@import "../../../../../assets/styles/Variables";


.exp-date {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.exp-title {
    letter-spacing: -0.5px;
    font-size: 2.5vh;
    font-weight: 600;
}

.exp-subtitle {
    // font-size:: 24px;
    // font-weight: 300;
    margin: 10px auto;
}

ul li {
    color: $textColor;
}

span {
    color: $textColor;
}

.spacer {
    margin: 0 10px;
}




@media only screen and (max-width: 578px) {

    .exp-row {
        padding: 0 15px;
    }

    .exp-date {
        margin: 5px 0 10px 0;
        flex-direction: row;
    }

}