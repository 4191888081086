@import './theme/Colors';

// Functions
@function random_range($min, $max) {
    @return $min + floor(random() * (($max - $min) + 1));
}
  
@function sample($list) {
    @return nth($list, random(length($list)));
}

@keyframes cssAnimation {
  to {
    border: none;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    border: none;
  }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: $whiteColor; }
}

// Mixin
@mixin background-gradient($backgroundColor) {
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $backgroundColor));
}

@mixin center() {    
    // top: 50%;
    // left: 50%;
    margin: auto;
    // position: fixed;
    // transform: translate(-50%, -50%);
}