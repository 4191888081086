@import "../../assets/styles/Variables.scss";

.card h4 p {
    color: white;
}

.card {
    border: none;
    background: none;
    text-align: center;
    padding: 25px 0 0 0;
    border-radius: 50px;
}

.card-img-top {
    height: 300px;
    width: auto;
}

.card-img-top:hover {
    transform: scale(1.015);
}


.card-cover {
    height: 300px;
    margin: auto;
    text-align: center;
}


.card-title {
    font-size: 25px;
    font-weight: 600;
    color: $textColor;
}

.card-text {
    // color: black;
}


.card-loading {
    width: 6rem;
    height: 6rem;
    margin: auto;
    color: $darkColor;
}