ul li {
    color: white;
}

h1 {
    font-size: 8vh;
}


h3 {
    font-size: 4.5vh !important;
    font-weight: 100;
    letter-spacing: 0;
    margin-bottom: 20px;
}

h2 {
    font-size: 3vh !important;
    font-weight: 300;
    letter-spacing: 0;
    margin-bottom: 20px;
}