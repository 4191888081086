@import '../../assets/styles/Variables.scss';

.tag {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: 100px;
  font-size: 16px;
  font-weight: inherit;
  line-height: 1;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  position: relative;
  background-color: #FFB600;
  color: #1A1A1A;
  padding: 8px 16px;
  margin: 0 15px 0 0;
}

@media only screen and (max-width: 1000px) {

  .tag {
    margin: 0 15px 15px 0;
  }

}