@import '../../../../assets/styles/Variables.scss';

.project-row {
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  align-content: center !important;
  justify-content: center !important;
  cursor: pointer;
  margin-bottom: 25px;
  border-radius: 16px;
  border: 0.5px solid $primaryColor !important;
  box-shadow: 0 6px 10px $lightOrangeColor, 0 0 6px $lightOrangeColor;
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}

.project-row:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px $lightSecondaryOrangeColor, 0 4px 8px $lightSecondaryOrangeColor;
}

.project-description {
  margin: 25px 0;
}

.project-clipart {
  text-align: center;
}

.project-title {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .project-title {
    margin: 10px 0;
  }
}

.project-cover {
  max-height: 300px;
  padding: 10px;
}

.project-card {
  max-width: 300px;
  height: auto;
  margin: 0;
  padding: 0 !important;
  text-align:center;
  overflow: hidden;
}

.caption {
  position:relative;
  height:40%;
  padding:10px;
}

.project-disabled {
  color: gray;
  pointer-events: none;
}