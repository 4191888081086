// Colors
$whiteColor: #FFFFFF;
$blackColor: #000000;

$lightOrangeColor: rgba(0, 156, 73, 0.1);
$lightSecondaryOrangeColor: rgba(0, 156, 73, 0.2);

$clearColor:  rgba(0, 156, 73, 0.8);   // #FF7900
$darkColor: rgb(0, 156, 73); // #FF7800

$textColor: var(--textColor);
$backgroundColor: var(--backgroundColor);

$primaryColor: $clearColor;
$primaryVariantColor: $darkColor;

$secondaryColor: #000;
$secondaryVariantColor: #000;