@import '../../assets/styles/Variables.scss';


.overlay {
    top: 0;
    right: 0;
    width: 0;
    z-index: 1;
    height: 100%;
    position: fixed;
    transition: 0.35s;
    overflow-x: hidden;
    background-color: #000000;
    background-color: rgba(0,0,0, 0.9);
}

.overlay-shown {
    width: 100%;
}

.overlay-hidden {
    width: 0;
}

.overlay-content {
    top: 50%;
    width: 100%;
    text-align: center;
    position: absolute;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.overlay-languages {
    bottom: 0;
    width: 100%;
    position: absolute;
    text-align: center;
}

.overlay-content a {
    padding: 8px;
    display: block;
    // font-size:: 36px;
    color: #818181;
    transition: 0.3s;
    text-decoration: none;
}

.overlay-languages a {
    padding: 8px;
    display: block;
    // font-size:: 18px;
    color: #818181;
    transition: 0.3s;
    text-decoration: none;
}

.overlay a:hover, .overlay a:focus {
    color: $whiteColor;
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    // font-size:: 60px;
}

.language-btn {
    padding: 0;
    border: 0;
    background: none;
    color: #818181;
    margin-bottom: 20px;
    // font-size:: 20px;
}

.language-btn:hover {
    color: #F1F1F1;
}

.close {
    right: 0;
    top: 0;
    margin: 25px;
    width: 32px;
    height: 32px;
    opacity: 0.7;
    position: absolute;
}

.close:hover {
    opacity: 1;
}

.close:before, .close:after {
    width: 3px;
    height: 33px;
    content: ' ';
    position: absolute;
    background-color: #FFFFFF;
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}

/**
 * Width
 * iPhone 6/7/8 Plus - Width dimensions
 */
@media only screen and (max-width: 414px) {
    .overlay-content a {
        // font-size:: 30px;
    }

    .close {
        margin: 15px;
    }

    .language-btn {
        // font-size:: 18px;
    }
}

/**
 * Width
 * iPhone 6/7/8 & X - Width dimensions
 */
@media only screen and (max-width: 375px) {
    .overlay-content a {
        // font-size:: 24px;
    }

    .close {
        margin: 15px;
    }

    .language-btn {
        // font-size:: 18px;
    }
}

/**
 *
 * iPhone 5/SE - Width dimensions
 */
@media only screen and (max-width: 320px) {
    .overlay-content a {
        // font-size:: 22px;
    }

    .close {
        margin: 15px;
    }

    .language-btn {
        // font-size:: 15px;
    }
}

/**
 * Uncommon
 **/
@media only screen and (max-width: 270px) {

}