@import "../../../../../assets/styles/Variables";


.exp-date {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.exp-title {
    letter-spacing: -0.5px;
    font-size: 2.5vh;
    font-weight: 600;
}

.exp-subtitle {
    margin: 10px auto;
}

ul li {
    color: $textColor;
}

span {
    color: $textColor;
}

.separator {
    margin: 20px auto;
    border-top: 3px solid #bbb;
}