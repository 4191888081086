.title {
    font-size: 8vh;
    text-align: start;
}

.subtitle {
    text-align: start;
}

.scroll-bounce {
    overscroll-behavior: contain;
}


/**
 * Width
 * iPhone 6/7/8 Plus - Width dimensions
 */
@media only screen and (max-width: 414px) {}

/**
 * Width
 * iPhone 6/7/8 & X - Width dimensions
 */
@media only screen and (max-width: 375px) {}

/**
 *
 * iPhone 5/SE - Width dimensions
 */
@media only screen and (max-width: 320px) {}