@import "../../../assets/styles/Variables";

a {
    font-size: unset;
    color: #373b3e;
}

p {
    font-size: 2vh;
}

.vertical-timeline-element-title {
    margin: 0 !important;
    font-size: 2.3vh !important;
}

.vertical-timeline-element-subtitle {
    font-size: 2vh !important;
}

.vertical-timeline-element-content-arrow {
    border-right: 7px solid $primaryVariantColor !important;
}

.vertical-timeline-element-content {
    background: $primaryVariantColor !important;
    color: $textColor !important;
    box-shadow: 0 3px 0 $textColor !important;
}

.vertical-timeline::before {
    background: $textColor !important;
}

.vertical-timeline-element-icon {
    color: $textColor !important;
    background: $primaryVariantColor !important;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 .6s;
    animation: cd-bounce-1 .6s;
}
.vertical-timeline-element-icon {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

.vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px $textColor !important;
}

.memoji {
    height: unset;
}

.align-left {
    text-align: start;
}

.paragraph {
    text-space: 2px;
    // font-size:: 21px;
    padding: 15px 20px;
    font-family: 'Mukta-Light', serif;
}

a {
    text-decoration: none;
}

.spinner-grow {
    width: 6rem;
    height: 6rem;
    margin: 40px auto;
    color: $darkColor;
}

.align-center {
    text-align: center;
}


.apple-music {
    width:100%;
    max-width:660px;
    overflow:hidden;
    border-radius:10px;
}

@media only screen and (max-width: 1270px) {
    .memoji {
        width: 100%;
    }
}


@media only screen and (max-width: 421px) {
    .memoji {
        width: inherit;
    }
}

/**
 * Width
 * iPhone 6/7/8 Plus - Width dimensions
 */
@media only screen and (max-width: 414px) {
    .paragraph {
        text-space: 2px;
        padding: 15px 20px;
    }
}

/**
 * Width
 * iPhone 6/7/8 & X - Width dimensions
 */
@media only screen and (max-width: 375px) {
    .paragraph {
        text-space: 2px;
        padding: 15px 20px;
    }
}

/**
 *
 * iPhone 5/SE - Width dimensions
 */
@media only screen and (max-width: 320px) {
    .paragraph {
        text-space: 2px;
        padding: 15px 20px;
    }
}