.image {
  width: auto;
  margin: auto;
  max-height: 600px;
  height: 100%;
}

@media only screen and (max-width: 1000px) {

  img {
    width: auto;
    max-height: 450px !important;
    height: fit-content;
  }

}

@media only screen and (max-width: 770px) {

  img {
    width: auto;
    max-height: 350px !important;
    height: fit-content;
  }

}

@media only screen and (max-width: 550px) {

  img {
    width: auto;
    max-height: 300px !important;
    height: fit-content;
  }

}

@media only screen and (max-width: 375px) {

  img {
    width: auto;
    max-height: 200px;
    height: fit-content;
  }

}