// Variables

$overflow: auto;

// Width & Height
$width: calc(100vh - 18vh);

// Bubbles
$bubble-count: 25;
$sway-type: "sway-left-to-right", "sway-right-to-left";

@import "theme/Colors";

// Fonts Sizes
$heading1FontSize: 05.5vh;
$heading2FontSize: 04.5vh;
$heading3FontSize: 03.5vh;
$heading4FontSize: 03.0vh;
$heading5FontSize: 02.5vh;
$heading6FontSize: 02.0vh;

$subTitle1FontSize: 13px;
$subTitle2FontSize: 13px;

$body1FontSize: 13px;
$body2FontSize: 13px;

$largeButtonFontSize: 5vh;
$buttonFontSize: 2.33vh;
$smallButtonFontSize: 2vh;

$captionFontSize: 10px;

$overlineFontSize: 10px;

// Fonts Weight
$heading1FontWeight: 100;
$heading2FontWeight: 100;
$heading3FontWeight: 100;
$heading4FontWeight: 100;
$heading5FontWeight: 100;
$heading6FontWeight: 100;

$subTitle1FontWeight: 100;
$subTitle2FontWeight: 100;

$body1FontWeight: 100;
$body2FontWeight: 100;

$buttonFontWeight: 300;

$captionFontWeight: 100;

$overlineFontWeight: 100;

// Letter Spacing
$heading1LetterSpacing: -2px;
$heading2LetterSpacing: 0px;
$heading3LetterSpacing: 0px;
$heading4LetterSpacing: 0px;
$heading5LetterSpacing: 0px;
$heading6LetterSpacing: 0px;

$subTitle1LetterSpacing: 0px;
$subTitle2LetterSpacing: 0px;

$body1LetterSpacing: 0px;
$body2LetterSpacing: 0px;

$buttonLetterSpacing: 0px;

$captionLetterSpacing: 0px;

$overlineLetterSpacing: 0px;








/**
 *
 * Mobile
 *
 **/

// Fonts Sizes
$heading1MobileFontSize: 06.0vh;    // TO VERIFY
$heading2MobileFontSize: 05.0vh;    // TO VERIFY
$heading3MobileFontSize: 03.5vh;    // TO VERIFY
$heading4MobileFontSize: 03.0vh;    // TO VERIFY
$heading5MobileFontSize: 02.0vh;    // TO VERIFY
$heading6MobileFontSize: 01.0vh;    // TO VERIFY

$subTitle1MobileFontSize: 3vh;
$subTitle2MobileFontSize: 3vh;

$body1MobileFontSize: 2vh;
$body2MobileFontSize: 1.8vh;

$largeButtonMobileFontSize: 4vh;
$buttonMobileFontSize: 2vh;
$smallButtonMobileFontSize: 10px;

$captionMobileFontSize: 10px;

$overlineMobileFontSize: 10px;

// Letter Spacing
$heading1MobileLetterSpacing: 0px;
$heading2MobileLetterSpacing: 0px;
$heading3MobileLetterSpacing: 0px;
$heading4MobileLetterSpacing: 0px;
$heading5MobileLetterSpacing: 0px;
$heading6MobileLetterSpacing: 0px;

$subTitle1MobileLetterSpacing: 0px;
$subTitle2MobileLetterSpacing: 0px;

$body1MobileLetterSpacing: 0px;
$body2MobileLetterSpacing: 0px;

$buttonMobileLetterSpacing: 0px;

$captionMobileLetterSpacing: 0px;

$overlineMobileLetterSpacing: 0px;

// Fonts Weight
$heading1MobileFontWeight: 100;
$heading2MobileFontWeight: 100;
$heading3MobileFontWeight: 100;
$heading4MobileFontWeight: 100;
$heading5MobileFontWeight: 100;
$heading6MobileFontWeight: 100;

$subTitle1MobileFontWeight: 100;
$subTitle2MobileFontWeight: 100;

$body1MobileFontWeight: 100;
$body2MobileFontWeight: 100;

$buttonMobileFontWeight: 100;

$captionMobileFontWeight: 100;

$overlineMobileFontWeight: 100;
