.blog-card {
  width: min-content !important;
  margin: 0 10px
}

.col {
  display: inline-flex !important;
}

.card {
  display: grid;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
