.blog-img-middle {
  width: 350px !important;
  display: flex !important;
  text-align: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin: 25px 10px
}

.center-row {
  display: flex !important;
  margin: auto;
  text-align: center;
  vertical-align: center;

  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

p {
  margin-bottom: 12px !important;
}

.title {
  font-size: 5vh !important;
  margin: 10px 0;
}

h2 {
  margin-top: 2vh !important;
  font-size: 4vh !important;
  font-weight: 300 !important;
}

h3 {
  margin-top: 2vh !important;
  font-size: 3vh !important;
}

h4 {
  margin-top: 1.5vh !important;
  margin-bottom: 1.5vh !important;
  font-size: 2.5vh !important;
}