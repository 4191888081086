@import '../../assets/styles/Variables.scss';

header {
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 10vh;
}

.icon {
    top: 0;
    right: 0;
    z-index: 1;
    width: 10vh;
    height: 10vh;
    cursor: pointer;
    position: fixed;
    text-align: center;
    display: inline-flex;
    background-color: transparent;
    transition: background-color 300ms ease;
}

.icon:hover {
    background-color: $primaryColor;
}

.theme-changer {
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    position: fixed;
    text-align: center;
    display: inline-flex;
    background-color: transparent;
    transition: background-color 300ms ease;
}

.theme-changer-icon {
    height: 28px;
    width: 28px;
}

.menu {
    margin: auto;
    display: inline-block;
}

#header span {
    height: 3px;
    width: 30px;
    display: block;
    margin-bottom: 6px;
    position: relative;
    border-radius: 40px;
    background: $textColor;
}

#header span:first-child {
    transform-origin: 0 0;
}

#header span:nth-last-child(2) {
    transform-origin: 0 100%;
}

#header span:last-child {
    margin-bottom: 0;
}

/**
 * Width
 * iPhone 6/7/8 Plus - Width dimensions
 */
@media only screen and (max-width: 414px) {
    header {
        width: 100%;
        height: 60px;
    }

    .icon {
        width: 60px;
        height: 60px;
        padding: 10px;
    }

    #header span {
        height: 4px;
        width: 28px;
        margin-bottom: 5px;
        border-radius: 23px;
    }
}

/**
 * Width
 * iPhone 6/7/8 & X - Width dimensions
 */
@media only screen and (max-width: 375px) {
    header {
        width: 100%;
        height: 55px;
    }

    .icon {
        width: 55px;
        height: 55px;
        padding: 10px;
    }

    #header span {
        height: 3px;
        width: 26px;
        margin-bottom: 5px;
        border-radius: 23px;
    }
}

/**
 *
 * iPhone 5/SE - Width dimensions
 */
@media only screen and (max-width: 320px) {
    header {
        width: 100%;
        height: 50px;
    }

    .icon {
        width: 50px;
        height: 50px;
        padding: 10px;
    }

    #header span {
        height: 3px;
        width: 24px;
        margin-bottom: 4px;
        border-radius: 23px;
    }
}