// Fonts

@font-face {
	font-family: 'ZenTokyoZoo';
	src: local('ZenTokyoZoo'), url(/assets/fonts/ZenTokyoZoo.ttf) format('truetype');
}

@font-face {
	font-family: 'RobotoMono';
	src: local('RobotoMono'), url(/assets/fonts/RobotoMono/RobotoMono.ttf) format('truetype');
}

@font-face {
	font-family: 'RobotoMono-Italic';
	src: local('RobotoMono'), url(/assets/fonts/RobotoMono/RobotoMono-Italic.ttf) format('truetype');
}

@font-face {
	font-family: 'Mukta';
	src: local('Mukta'), url(/assets/fonts/Mukta/Mukta-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Mukta-ExtraLight';
	src: local('Mukta-ExtraLight'), url(/assets/fonts/Mukta/Mukta-ExtraLight.ttf) format('truetype');
}

@font-face {
	font-family: 'Mukta-Light';
	src: local('Mukta-Light'), url(/assets/fonts/Mukta/Mukta-Light.ttf) format('truetype');
}

h1 {
	text-align: center;
	font-family: 'ZenTokyoZoo', serif;
}

h3 {
	font-family: 'Mukta-Light', serif;
}