@import '../../../assets/styles/Variables';

.holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.center {
    height: 65px;
    display: flex;
    color: $textColor;
    position: relative;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}

button {
    font: inherit;
    margin: 0.5em;
    line-height: 1;
    padding: 1em 2em;
    background: none;
    border: 2px solid;
}

button {
    color: var(--color);
    transition: 0.25s;

    &:hover, &:focus {
        border-color: var(--hoverColor);
        color: var(--hoverTextColor);
    }
}

.fill:hover, .fill:focus {
    box-shadow: inset 0 0 0 2em var(--hover);
}

.pulse:hover, .pulse:focus {
    animation: pulse 1s;
    box-shadow: 0 0 0 2em rgba(#fff,0);
}

.raise:hover, .raise:focus {
    transform: translateY(-0.25em);
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
}

.up:hover, .up:focus {
    box-shadow: inset 0 -3.25em 0 0 var(--hover);
}

.slide:hover, .slide:focus {
    box-shadow: inset 200px 0 0 0 var(--hover);
}

.offset {
    box-shadow:
        0.3em 0.3em 0 0 var(--color),
        inset 0.3em 0.3em 0 0 var(--color);

    &:hover, &:focus {
        box-shadow:
            0 0 0 0 var(--hover),
            inset 6em 3.5em 0 0 var(--hover);
    }
}

$colors:
        "up" $textColor $darkColor $textColor,         // OK
        "fill" $textColor $darkColor $textColor,       // OK
        "slide" $textColor $darkColor $textColor,      // OK
        "offset" $textColor $darkColor $textColor,     // OK
        "pulse" $textColor $darkColor $darkColor, // OK
        "raise" $textColor $darkColor $darkColor; // OK

@keyframes pulse {
    0% { box-shadow: 0 0 0 0 var(--hover); }
}

@each $button, $color, $hoverColor, $hoverTextColor in $colors {
    .#{$button} {
        --color: #{$color};
        --hover: #{$hoverColor};
        --hoverColor: #{$hoverColor};
        --hoverTextColor: #{$hoverTextColor};
    }
}

/**
 * Width
 * iPhone 6/7/8 Plus - Width dimensions
 */
@media only screen and (max-width: 414px) {
    button {
        // font-size:: 15px;
        // // font-weight: 300;
    }
}

/**
 * Width
 * iPhone 6/7/8 & X - Width dimensions
 */
@media only screen and (max-width: 375px) {
    button {
        // font-size:: 14px;
        // font-weight: 300;
    }
}

/**
 *
 * iPhone 5/SE - Width dimensions
 */
@media only screen and (max-width: 320px) {
    button {
        // font-size:: 13px;
        // font-weight: 200;
    }
}


