.skill-center {
    margin: auto;
    text-align: center;
}

.skill-icon {
    height: 4em;
    margin: 12px;
    color: #0a53be;
}

.skill-title {
    margin: 10px auto;
    text-align: center;
}

.skill-title h3 {}